.footerSocial {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;
	grid-area: social;
	@media (width >= 768px) {
		margin-bottom: 0;
	}
	@media (width >= 992px) {
		justify-content: flex-end;
		width: 100%;
	}
	&__item {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 35px;
		height: 35px;
		margin-left: 6px;
		margin-right: 6px;
		@media (width >= 768px) {
			margin-left: 0;
			margin-right: 15px;
		}
		@media (width >= 992px) {
			margin-right: 0;
			margin-left: 15px;
		}
	}
	&__link {
		display: flex;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		border: 1px solid var(--orange);
		background-color: var(--orange);
		transition: background-color .3s;
		&:hover {
			background-color: white;
		}
		path {
			fill: white;
			transition: fill .3s;
		}
		&:hover path {
			fill: var(--orange);
		}
	}
}