html {
	box-sizing: border-box;
}
*, *::before, *::after {
	box-sizing: inherit;
}

body {
	min-width: 320px;
	margin: 0;
	font-family: 'Open Sans', 'Helvetica', sans-serif;
	font-weight: normal;
	line-height: 1.5;
	--cyan: #1E95E1;
	--orange: #FF6000;
	--innerIndent: 40px;
	@media (width >= 768px) {--innerIndent: 45px;}
	@media (width >= 1200px) {--innerIndent: 65px;}
}

input, textarea {
	font-family: 'Open Sans', 'Helvetica', sans-serif;
}

a {
	outline-color: transparent!important;
}

a:hover,
a:focus {
	text-decoration: none;
}

ul {
	margin-top: 0;
	margin-bottom: 0;
	list-style: none;
	padding-left: 0;
}

@media (width >= 992px) {body {--container: 960px;}}
@media (width >= 1200px) {body {--container: 1140px;}}
@media (width >= 1440px) {body {--container: 1320px;}}

.container {
	width: 100%;
	max-width: var(--container);
	margin-left: auto;
	margin-right: auto;
}


@media (width <= 991px) {
	.container {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.certificates {display: none;}