.newsItem {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: 290px;
	@media (width >= 480px) {
		max-width: 460px;
	}
	@media (width >= 768px) {
		max-width: none;
	}
	&__img {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: opacity .3s;
		opacity: 1;
		margin-bottom: 32px;
		margin-left: auto;
		margin-right: auto;
		picture,img {
			width: 100%;
			height: auto;
			display: block;
		}
		&:hover {opacity: 0.7;}
	}
	&__heading {
		font-size: 18px;
		font-weight: bold;
		line-height: 1.2;
		color: black;
		text-decoration: none;
		margin-bottom: 10px;
	}
	&__subheading {
		font-size: 18px;
		color: black;
		margin-bottom: 20px;
	}
	&__date {
		display: flex;
		align-items: center;
		span {
			font-size: 18px;
			color: #999;
			margin-left: 10px;
		}
	}
	&__link {
		border: 1px solid var(--orange);
		box-shadow: none;
	}
}