.headerQuestionResponsive {
	width: 100%;
	background-color: var(--cyan);
	padding: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	.headerQuestionBtn {
		border-color: white;
		&:hover {
			border-color: var(--orange);
		}
	}
	@media (width >= 1200px) {
		display: none;
	}
}