.contactsContent {
	background-color: white;
	padding-top: var(--innerIndent);
	&__heading {
		@media (width >= 768px) {
			padding-top: 0;
		}
	}
	&__wrapper {
		width: 100%;
	}
}

.contact {
	width: 100%;
	background-color: #FAFAFA;
	padding-top: 75px;
	padding-bottom: 30px;	
	position: relative;
	&::before {
		position: absolute;
		display: block;
		content: "";
		width: 100%;
		height: 45px;
		width: 100%;
		background-color: white;
		top: 0;
		left: 0;
	}
	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		@media (width >= 992px) {
			flex-direction: row;
		}
	}
	&__details {
		margin-bottom: 20px;
		width: 100%;
		max-width: 540px;
		@media (width >= 992px) {
			flex-basis: 50%;
			max-width: none;
			padding-right: 30px;
		}
	}
	&__title {
		display: block;
		margin-top: 0;
		margin-bottom: 25px;
		text-align: center;
		font-size: 24px;
		color: black;
		@media (width >= 768px) {font-size: 28px;}
		@media (width >= 992px) {text-align: left;}
	}
	&__data {
		display: flex;
		align-content: flex-start;
		margin-bottom: 10px;
	}
	&__icon {
		flex-basis: 50px;
		width: 50px;
		height: 50px;
		background-color: var(--orange);
		border-radius: 25px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__text {
		flex-basis: calc(100% - 65px);
		margin-left: 15px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		span,a {
			font-size: 16px;
			color: black;
		}
		a:not(:last-child) {
			margin-bottom: 5px;
		}
	}
	&__map {
		border: 4px solid white;
		box-shadow: 0 5px 5px rgba(0,0,0,.1);
		max-width: 540px;
		width: 100%;
		@media (width >= 992px) {
			flex-basis: 50%;
			max-width: none;
		}
	}
	.google-map {
		width: 100%;
		height: 300px;
	}
}