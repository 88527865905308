.itemBenefits {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: stretch;
	&__item {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		padding: 45px 15px 30px;
		border-bottom: 1px solid #CDCDCD;
		@media (width >= 640px) {
			flex-basis: 50%;
			border-right: 1px solid #CDCDCD;
			&__item--1,&__item--3 {
				border-right: 1px solid #CDCDCD;
			}
		}
		@media (width >= 1200px) {
			flex-basis: 25%;
			&__item--2 {
				border-right: 1px solid #CDCDCD;
			}
		}
	}
	&__icon {
		border-radius: 20px;
		width: 100px;
		height: 100px;
		overflow: hidden;
		transform: rotate(45deg);
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: 10px 10px 15px rgba(0,0,0,0.1);
		svg {
			transform: rotate(-45deg);
		}
	}
	&__text {
		font-size: 16px;
		color: black;
		text-align: center;
		padding-top: 30px;
		margin-top: auto;
	}
}