.headerQuestion {
	display: none;
	@media (width >= 992px) {
		display: block;
	}
}

.headerQuestionBtn {
	width: 180px;
	height: 50px;
	border-radius: 50px;
	appearance: none;
	cursor: pointer;
	color: var(--orange);
	border: 1px solid var(--orange);
	font-size: 14px;
	text-transform: uppercase;
	text-align: center;
	outline: none;
	&:hover {
		color: #FFF;
	}
}