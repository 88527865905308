.headerPhone {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	position: relative;
	width: 290px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
	@media (width >= 768px) {
		margin-left: 0;
		margin-right: 0;
	}
	@media (width >= 1200px) {
		margin-bottom: 0;
	}
	&__text {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding-left: 10px;
		padding-right: 10px;
	}
	&__mainTel {
		color: #333;
		font-size: 18px;
		line-height: 21px;
		font-weight: bold;
		letter-spacing: 1px;
		text-decoration: none;
		border-bottom: 1px solid transparent;
		transition: border-bottom-color .3s;
		margin-bottom: 5px;
		&:hover {
			border-bottom-color:currentColor;
		}
		@media (width >= 992px) {
			margin-bottom: 0;
		}
	}
	&__callBack {
		color: #333;
		font-size: 14px;
		line-height: 21px;
		text-decoration: none;
		border-bottom: 1px dashed var(--cyan);
		&:hover {
			border-bottom-style: solid;
		}
	}
	&__trigger {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 20px;
		cursor: pointer;
		position: relative;
		z-index: 4;
	}
	&__dropdown {
		width: 100%;
		background-color: #FFF;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 10px 20px;
		box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.15);
		border-radius: 5px;
		position: absolute;
		z-index: 3;
		top: -10px;
		left: 0;
	}
	&__allTel {
		color: #333;
		font-size: 18px;
		line-height: 20px;
		font-weight: bold;
		letter-spacing: 1px;
		text-decoration: none;
		position: relative;
		&:not(:last-child) {
			margin-bottom: 10px;
		}
		&::before {
			position: absolute;
			display: block;
			content: "";
			top: 0;
			left: 0;
			height: 100%;
			width: 30px;
			background-position: center;
			background-size: 20px 20px;
			background-repeat: no-repeat;
			transition: filter .3s;
		}
		span {
			border-bottom: 1px solid transparent;
			transition: border-bottom-color .3s;
			margin-left: 42px;
		}
		&:hover span {
			border-bottom-color:currentColor;
		}
		&:hover::before {
			filter: grayscale(0) opacity(1);
		}
	}
	&__allTel--logoKievstar::before {
		filter: grayscale(1) opacity(.5);
		background-image: url(../img/iconKievstar.svg);
	}
	&__allTel--logoVodafone::before {
		filter: grayscale(1) opacity(.25);
		background-image: url(../img/iconVodafone.svg);
	}
}