.back-to-top {
	width: 50px;
	height: 50px;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	transform: rotate(45deg);
	box-shadow: 0 0 10px rgba(0,0,0,.2);
	cursor: pointer;
	position: fixed;
	bottom: 135px;
	right: 55px;
	z-index: 10;
}

.back-to-top svg {
	transform: rotate(-135deg);
	height: 18px;
	width: 15px;
}

@media (width <= 1199px) {
	.back-to-top {
		display: none;
	}
}