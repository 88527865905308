.pageHeading {
	color: black;
	display: block;
	padding-top: 40px;
	margin-bottom: 30px;
	padding-bottom: 20px;
	position: relative;
	font-size: 24px;
	font-weight: bold;
	text-align: center;
	@media (width >= 768px) {
		font-size: 38px;
		margin-bottom: 40px; 
	}
	&::after {
		position: absolute;
		display: block;
		content: "";
		width: 40px;
		height: 2px;
		background-color: var(--orange);
		bottom: 0;
		left: calc(50% - 20px);
	}
}