.indexBanner {
	width: 100%;
	&__item {
		width: 100%;
		position: relative;
	}
	picture,img {
		display: block;
		width: 100%;
		object-fit: cover;
		object-position: center;
		height: 300px;
		@media (width >= 480px) {height: 400px;}
		@media (width >= 992px) {height: 420px;}
		@media (width >= 1200px) {height: 580px;}
		@media (width >= 1800px) {height: 640px;}
	}
	&__text {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 15px;
	}
	&__heading {
		color: #FFF;
		text-align: center;
		font-weight: 700;
		line-height: 1;
		font-size: 30px;
		margin-bottom: 10px;
		@media (width >= 768px) {font-size: 40px;}
		@media (width >= 992px) {font-size: 50px;}
	}
	&__subheading {
		color: #FFF;
		text-align: center;
		margin-bottom: 20px;
		font-size: 16px;
		@media (width >= 992px) {font-size: 26px;}
	}
	&__links {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	&__link {
		height: 50px;
		font-size: 14px;
		line-height: 1;
		font-weight: 700;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 25px;
		text-decoration: none;
		--btnPadding: 16px;
		margin: 0 var(--btnPadding) var(--btnPadding) var(--btnPadding);
		padding: var(--btnPadding) calc(var(--btnPadding) * 2);
		box-shadow: 0 16px 11px rgba(0, 0, 0, .15);
		width: 100%;
		max-width: 250px;
		@media (width >= 480px) {
			width: auto;
		}
	}
}