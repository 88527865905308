.footerSubscribe {
	margin-bottom: 30px;
	grid-area: subscribe;
	@media (width >= 768px) {
		margin-bottom: 10px;
		width: 100%;
	}
	&__title {
		color: #000;
		font-size: 12px;
		line-height: 1;
		font-weight: bold;
		text-transform: uppercase;
		text-align: center;
		display: block;
		margin-bottom: 15px;
		@media (width >= 768px) {
			font-size: 14px;
			text-align: left;
		}
	}
	&__form{
		display: flex;
		justify-content: space-between;
		width: 100%;
		max-width: 440px;
		margin-left: auto;
		margin-right: auto;
		height: 46px;
		border: 1px solid var(--orange);
		transition: box-shadow .3s;
		position: relative;	
		&:focus-within {
			box-shadow: 1px 1px 3px 1px rgba(255, 96, 0, .4);
		}
	}
	&__email, &__submit {
		padding: 0 10px;
		display: flex;
		align-items: center;
		appearance: none;
		border: none;
		height: 44px;
		outline: none;
	}
	&__email {
		width: calc(100% - 46px);
		background-color: transparent;
		font-size: 20px;
		line-height: 26px;
		color: var(--orange);
		caret-color: var(--orange);
		&::-webkit-input-placeholder {color: #A5AEB5;}
		&::-moz-placeholder {color: #A5AEB5;}
		&::-ms-input-placeholder {color: #A5AEB5;}
	}
	&__submit {
		font-size: 0;
		border-radius: 0;
		width: 46px;
		background-color: var(--orange);
		border-left: 1px solid var(--orange);
		transition: background-color .3s;
		cursor: pointer;
		&:hover {background-color: transparent;}
	}
	&__arrow {
		position: absolute;
		top: 0;
		right: 0;
		width: 40px;
		height: 100%;
		background-color: transparent;
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: none;
		path {transition: fill .3s;}
	}
}

.footerSubscribe__submit:hover + .footerSubscribe__arrow path {fill: var(--orange)}