.btnGradient {
	background-size: 300% auto;
	transition-duration: .5s;
	transition-property: color, border-color, background-position;
}

.btnGradient:hover {
	background-position: 99% center;
	color: #FFF;
}

.btnGradient--orange {
	color: var(--orange);
	background-image: linear-gradient(to right, white 35%, var(--orange) 70%, var(--orange) 99%);	
}

.btnGradient--cyan {
	border: 1px solid white;
	color: #FFF;
	background-image: linear-gradient(to right, transparent 35%, var(--cyan) 70%, var(--cyan) 99%);	
}

.btnStandard {
	height: 50px;
	font-size: 14px;
	line-height: 1;
	font-weight: 700;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 25px;
	text-decoration: none;
	--btnPadding: 16px;
	margin: 0 var(--btnPadding) var(--btnPadding) var(--btnPadding);
	padding: var(--btnPadding) calc(var(--btnPadding) * 2);
	box-shadow: 0 16px 11px rgba(0, 0, 0, .15);
	width: 100%;
	max-width: 250px;
	@media (width >= 480px) {
		width: auto;
	}	
}

.squareGradient {
	background-size: 300% auto;
	transition-duration: .5s;
	transition-property: color, border-color, background-position;
}

.squareGradient:hover {
	background-position: 99% center;
	color: #FFF;
}

.squareGradient--cyanToOrange {
	color: var(--orange);
	background-image: linear-gradient(135deg,var(--cyan) 45%,#fff 50%,var(--orange) 60%,var(--orange) 99%);
}

.squareGradient--whiteToOrange {
	color: var(--orange);
	background-image: linear-gradient(135deg,  white 45%, var(--orange) 50%, var(--orange) 99%);	
}