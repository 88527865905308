.paymentContent {
	background-color: white;
	padding-top: var(--innerIndent);
	&__heading {
		@media (width >= 768px) {
			padding-top: 0;
		}
	}
	&__subheading {
		display: block;
		font-size: 20px;
		color: black;
		text-align: center;
		margin-bottom: 50px;
		max-width: 740px;
		margin-left: auto;
		margin-right: auto;
	}
	&__wrapper {
		width: 100%;
	}
}