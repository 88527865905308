.cta {
	padding: 45px 20px 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-image: url(../img/index/cta--320.jpg);
	@media (width >= 480px) {background-image: url(../img/index/cta--480.jpg);}
	@media (width >= 768px) {background-image: url(../img/index/cta--768.jpg);}
	@media (width >= 992px) {background-image: url(../img/index/cta--992.jpg);}
	@media (width >= 1200px) {background-image: url(../img/index/cta--1200.jpg);}
	@media (width >= 1440px) {background-image: url(../img/index/cta--1440.jpg);}
	@media (width >= 1800px) {background-image: url(../img/index/cta--1800.jpg);}
	&__heading {
		font-size: 20px;
		line-height: 1.2;
		font-weight: bold;
		color: white;
		margin-bottom: 20px;
		text-align: center;
		max-width: 650px;
		@media (width >= 768px) {font-size: 32px;}
		@media (width >= 1440px) {font-size: 38px;}
	}
	&__subheading {
		font-size: 16px;
		color: white;
		margin-bottom: 40px; 
		text-align: center;
	}
}

.ctaLinks {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	@media (width >= 768px) {
		flex-direction: row;
		justify-content: center;
		margin-top: 40px;
	}
	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-decoration: none;
		margin-bottom: 30px;
		@media (width >= 768px) {flex-basis: 33%;}
		@media (width >= 992px) {flex-basis: 360px;}
	}
	&__img {
		border-radius: 20px;
		width: 60px;
		height: 60px;
		overflow: hidden;
		transform: rotate(45deg);
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: 10px 10px 15px rgba(0,0,0,0.1);
		svg {
			transform: rotate(-45deg);
			path {transition: fill .3s;}
		}
		&:hover path {fill: white;}
	}
	&__text {
		margin-top: 10px;
		font-size: 16px;
		color: white;
		border-bottom: 1px solid transparent;
		transition: border-bottom-color .3s;
	}
	&__item:hover .ctaLinks__text {
		border-bottom-color: currentColor;
	}
}