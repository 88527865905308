.additionalEquipment {
	background-color: #FAFAFA;
	padding-bottom: 45px; 
	&__heading {
		@media (width >= 768px) {
			font-size: 32px;
		}
	}
	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		@media (width >= 1200px) {
			max-width: 750px;
			margin-left: auto;
			margin-right: auto;
		}
		@media (width >= 1440px) {
			max-width: none;
		}
	}
}

.equipCard {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 290px;
	margin-right: auto;
	margin-left: auto;
	margin-top: 15px;
	background-color: #FFF;
	box-shadow: 0 3px 6px rgba(0,0,0,.1);
	@media (width >= 768px) {
		margin: 5px;
		max-width: 320px;
		padding-top: 15px;
	}
	&__img {
		width: 290px;
		height: 290px;
		text-decoration: none;
		transition: opacity .3s;
		&:hover {
			opacity: 0.7;
		}
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&__name {
		color: #000;
		font-size: 20px;
		font-weight: bold;
		margin-top: 20px;
		margin-bottom: 10px;
		padding-left: 15px;
		padding-right: 15px;
		text-align: center;
		text-decoration: none;
		transition: opacity .3s;
		&:hover {
			opacity: 0.7;
		}
	}
	&__presence {
		font-size: 16px;
		margin-top: auto;
		margin-bottom: 20px;
		color: #999;
		text-align: center;
	}
	&__presence--yes {
		color: var(--orange);
	}
	&__request {
		width: 100%;
		height: 60px;
		background-color: var(--orange);
		display: flex;
		justify-content: center;
		align-items: center;
		color: white;
		transition: background-color .3s;
		text-decoration: none;
		&:hover {
			background-color: var(--cyan);
		}
		span {
			margin-left: 10px;
			font-size: 13px;
			font-weight: bold;
			text-transform: uppercase;
		}
		path {
			fill: currentColor;
		}
	}
}