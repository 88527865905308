.indexCatalogue {
	width: 100%;
	background-color: #FFF;
	&__wrapper {
		width: 100%;
		position: relative;
	}
	&__slider {
		padding-bottom: 20px;
		@media (width >= 1200px) {
			padding-bottom: 0;
		}
	}
	.owl-carousel.owl-theme .owl-dots {
		span {
			background-color: var(--orange);
			opacity: 0.5;
		}
		.active span {opacity: 1;}
	}
	&__controls {
		display: none;
		@media (width >= 1200px) {
			border-left: 1px solid #D9E2E9;
			transition: border-left-color .1s;
			position: absolute;
			z-index: 1;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			display: flex;
			justify-content: space-between;
			pointer-events: none;
			.left,.right {
				width: 40px;
				height: 40px;
				background-color: white;
				border-radius: 10px;
				box-shadow: 5px 5px 15px rgba(0,0,0,0.1);
				pointer-events: all;
				z-index: 1;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
			}
			path {
				fill: black;
				transition: fill .3s;
			}
			.left {transform: translate(-21px,94px) rotate(45deg);}
			.right {transform: translate(19px,94px) rotate(45deg);}
			.left svg {transform: rotate(135deg) translateX(2px);}
			.right svg {transform: rotate(-45deg) translateX(2px);}
			div:hover path {fill: #E8F0FF}
			div {transition: background-color .3s;}
			div:hover { background-color: var(--orange) }
		}
	}
	&__controls.active {
		@media (width >= 1200px) {
			border-left-color: transparent;
		}		
	}
}