.testimonialsSlide {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 20px;
	max-width: 750px;
	margin-left: auto;
	margin-right: auto;
	&__logo {
		border-radius: 20px;
		width: 80px;
		height: 80px;
		overflow: hidden;
		transform: rotate(45deg);
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: 10px 10px 15px rgba(0,0,0,0.1);
		transition: box-shadow .3s;
		img {
			transform: rotate(-45deg);
			width: 110px;
			height: 110px;
			object-fit: cover;
			object-position: center;
		}
	}
	&__content {
		width: 100%;
		border-radius: 5px;
		background-color: #FFF;
		margin-top: -40px;
		padding: 70px 15px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&__heading {
		color: #000;
		font-size: 17px;
		font-weight: bold;
		margin-top: 0;
		margin-bottom: 5px;
		text-align: center;
		@media (width >= 768px) {
			font-size: 20px;
		}
	}
	&__subheading {
		color: #999999;
		font-size: 17px;
		margin-top: 0;
		margin-bottom: 15px;
		text-align: center;
		@media (width >= 768px) {
			font-size: 20px;
		}
	}
	&__text {
		color: #000;
		font-size: 18px;
		margin-top: 0;
		margin-bottom: 20px;
		text-align: center;
	}
	&__link {
		font-size: 16px;
		margin-top: 0;
		margin-bottom: 0;
		color: var(--orange);
		text-decoration: underline;
		text-decoration-color: currentColor;
		transition: color .3s;
		text-align: center;
		&:hover {
			text-decoration: underline;
			color: var(--cyan);
		}
		@media (width >= 480px) {
			text-decoration: none;
			border-bottom: 1px solid currentColor;
			&:hover {
				text-decoration: none;
			}
		}
	}
}

.indexTestimonials__slides.active {
	.testimonialsSlide__logo {
		box-shadow: none;
	}
}