.footerMenu {
	display: none;
	grid-area: menu;
	@media (width >= 768px) {
		display: block;
	}
	&__link {
		font-size: 16px;
		line-height: 2;
		color: #000;
		text-decoration: none;
		transition: color .3s;
		&:hover, &.active {
			color: var(--orange);
		}
	}
}