.defaultText {
	p, li {
		font-size: 16px;
		color: black;
		margin-top: 0;
		margin-bottom: 20px;
		a {
			color: var(--orange);
			text-decoration: none;
			border-bottom: 1px solid currentColor;
			transition: border-bottom-color .3s;
			&:hover {
				border-bottom-color: transparent;
			}
		}
	}
	ul li {
		position: relative;
		padding-left: 40px;
		&::before {
			display: block;
			position: absolute;
			left: 0;
			top: 0px;
			content: "";
			width: 30px;
			height: 30px;
			background-size: 30px 30px;
			background-repeat: no-repeat;
			background-position: center;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+CiAgPHBhdGggZmlsbD0iI2ZmODEzMyIgZD0iTTEyLjI1Ljk0YTQuNDYgNC40NiAwIDAgMSAzLS45MyA0LjUzIDQuNTMgMCAwIDEgMi45IDEuMjlMMjMuODggN2wtMS4xMSAxLjEzTDE3IDIuNGEyLjkyIDIuOTIgMCAwIDAtNCAwTDIuNDYgMTIuOTFBMi45MiAyLjkyIDAgMCAwIDIuNCAxN0wxMyAyNy42MWEyLjk1IDIuOTUgMCAwIDAgNC4xIDBxNS4yMi01LjI0IDEwLjQ2LTEwLjQ2QTIuOTMgMi45MyAwIDAgMCAyNy42IDEzbC0yLTIgMS4xMi0xLjEyIDEuNTUgMS41NWE4LjQ1IDguNDUgMCAwIDEgLjgxLjg4IDQuNTMgNC41MyAwIDAgMSAwIDUuMzggMTMgMTMgMCAwIDEtLjk1IDFsLTkuNDMgOS40M2E4LjI5IDguMjkgMCAwIDEtMS4yOSAxLjE1IDQuNTMgNC41MyAwIDAgMS01LjYtLjU2TDIgMTguODVhMTAuNSAxMC41IDAgMCAxLTEuMTctMS4yNEE0LjQ0IDQuNDQgMCAwIDEgMCAxNS4yYTQuNDkgNC40OSAwIDAgMSAxLjI3LTMuMzVMMTEuNCAxLjczYTkgOSAwIDAgMSAuODUtLjc5eiIvPgogIDxwYXRoIGZpbGw9IiNmZjgxMzMiIGQ9Ik0yNS42OSA2Ljc5YS45LjkgMCAwIDEgMS40NS4yMS44Ny44NyAwIDAgMSAwIC44MyAxLjMxIDEuMzEgMCAwIDEtLjI3LjMxbC0zLjM3IDMuMzdjLTEuMDUgMS4wNy0yLjEyIDIuMTMtMy4xOCAzLjE5bC00LjY2IDQuNjZhLjg5Ljg5IDAgMCAxLTEuMjQgMGwtNC43NC00Ljc0YS44OS44OSAwIDAgMS0uMjUtLjYyLjg2Ljg2IDAgMCAxIC40Ny0uNzguOS45IDAgMCAxIDEuMDcuMTdsNC4wOCA0LjA3IDYuNzctNi43OHoiLz4KPC9zdmc+);
		}
	}

	ol {
		list-style: none;
		counter-reset: textCounter;
		padding-left: 0;
	}
	ol > li {
		position: relative;
		padding-left: 40px;
		&::before {
			counter-increment: textCounter;
			content: counter(textCounter);
			position: absolute;
			width: 25px;
			height: 25px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: var(--cyan);
			line-height: 25px;
			left: 0;
		}
		&::after {
			content: "";
			display: block;
			position: absolute;
			width: 25px;
			height: 25px;
			top: 0px;
			left: 0;
			border-radius: 5px;
			border: 1px solid var(--cyan);
			transform: rotate(45deg);
		}
	}

	h3 {
		margin-top: 0;
	}
}