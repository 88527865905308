.breadcrumbs {
	display: none;
	@media (width >= 992px) {
		display: block;
	}
}

.breadcrumbs__list {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	padding-left: 0;
	list-style: none;
	margin-top: 20px;
	margin-bottom: 20px;
	@media (width >= 1200px) {
		justify-content: flex-start;
	}
	li {
		position: relative;
		padding-right: 20px;
		margin-right: 20px;	
	}
	li:not(:last-child)::after {
		display: block;
		position: absolute;
		right: -10px;
		top: 1px;
		content: "";
		width: 20px;
		height: 100%;
		background-size: 6px 11px;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjExIiB2aWV3Qm94PSIwIDAgNiAxMSI+CiAgPHBhdGggZD0iTS4xMyAxMC44OGEuNDQuNDQgMCAwIDAgLjYyIDBMNS44NyA1LjhhLjQyLjQyIDAgMCAwIDAtLjZMLjc1LjEzYS40NC40NCAwIDAgMC0uNjIgMCAuNDMuNDMgMCAwIDAgMCAuNkw1IDUuNS4xMyAxMC4yN2EuNDMuNDMgMCAwIDAgMCAuNnoiLz4KPC9zdmc+);
	}
	path {
		transition: fill .3s;
		fill: black;
	}
	a:hover path {fill: var(--orange)}
	a {
		color: #000000;
		transition: color .3s;
		font-size: 16px;
		text-decoration: none;
		&:hover {
			color: var(--orange)
		}
	}
	span {
		font-size: 16px;
		color: #999;
	}

}
