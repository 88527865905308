.headerSearch {
	padding: 5px;
	&__form {
		width: 100%;
		height: 40px;
		background-color: #FAFAFA;
		display: flex;
		transition: box-shadow .3s;
		&:focus-within {
			box-shadow: inset 0 0 1px 2px rgba(0, 0, 0, 0.2);
		}
	}
	&__input {
		width: 100%;
		padding: 0 0 0 10px;
		background-color: transparent;
		width: 100%;
		height: 100%;
		font-size: 20px;
		line-height: 26px;
		border: none;
		display: flex;
		align-items: center;
		appearance: none;
		outline: none;
		color: var(--cyan);
		caret-color: var(--cyan);
		&::-webkit-input-placeholder {color: #A5AEB5;}
		&::-moz-placeholder {color: #A5AEB5;}
		&::-ms-input-placeholder {color: #A5AEB5;}
	}
	&__btn {
		display: flex;
		justify-content: center;
		align-items: center;
		appearance: none;
		border: none;
		height: 100%;
		width: 45px;
		outline: none;
		background-color: transparent;
		cursor: pointer;
	}
	@media (width >= 1200px) {
		order: 1;
		padding: 0;
		width: auto;
		margin-left: 5vw;
		&__form {
			height: 100%;
			&:focus-within {
				box-shadow: none;
			}
		}
		&__input {
			font-size: 14px;
			line-height: 1;
			text-transform: uppercase;
			padding-bottom: 1px;
			width: 140px;
			transition: width .3s;
			&:focus {
				width: 200px;
			}
		}
	}
}