.footerCopyright {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: -10px;
	margin-right: -10px;
	width: calc(100% + 20px);
	border-top: 1px solid #D9E2E9;
	padding-top: 15px;
	margin-bottom: 15px;
	grid-area: copyright;
	@media (width >= 768px) {
		margin-bottom: 0;
		margin-left: 0;
		margin-right: 0;
		border-top: none;
		justify-content: flex-start;
		width: 100%;
	}
	&__text {
		color: #999;
		font-size: 16px;
		line-height: 1;
		text-align: center;
		@media (width >= 768px) {
			text-align-last: auto;
		}
	}
}