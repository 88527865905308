.ctaPhone {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 280px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
	@media (width >= 768px) {
		margin-left: 0;
		margin-right: 0;
		width: 360px;
	}
	@media (width >= 1200px) {
		margin-bottom: 0;
	}
	&__icon {
		svg {display: block;}
		path {fill: white;}
	} 
	&__text {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding-left: 10px;
		padding-right: 10px;
	}
	&__mainTel {
		color: white;
		font-size: 18px;
		line-height: 21px;
		font-weight: bold;
		letter-spacing: 1px;
		text-decoration: none;
		border-bottom: 1px solid transparent;
		transition: border-bottom-color .3s;
		&:hover {border-bottom-color:currentColor;}
		@media (width >= 768px) {
			font-size: 28px;
		}
	}
	&__trigger {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 20px;
		cursor: pointer;
		position: relative;
		z-index: 4;
		path {
			fill: white;
		}
	}
	&__trigger.open {
		path {
			fill: black;
		}
	}
	&__dropdown {
		width: 100%;
		background-color: #FFF;
		/* display: flex; */
		flex-direction: column;
		align-items: flex-start;
		padding: 10px 16px;
		box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.15);
		border-radius: 5px;
		position: absolute;
		z-index: 3;
		top: -7px;
		left: 0;
	}
	&__allTel {
		color: #333;
		font-size: 18px;
		line-height: 20px;
		font-weight: bold;
		letter-spacing: 1px;
		text-decoration: none;
		position: relative;
		&:not(:last-child) {
			margin-bottom: 10px;
		}
		&::before {
			position: absolute;
			display: block;
			content: "";
			top: 0;
			left: 0;
			height: 100%;
			width: 30px;
			background-position: center;
			background-size: 20px 20px;
			background-repeat: no-repeat;
			
			transition: filter .3s;
		}
		@media (width >= 768px) {
			font-size: 25px;
			line-height: 25px;
		}
		span {
			border-bottom: 1px solid transparent;
			transition: border-bottom-color .3s;
			margin-left: 42px;
		}
		&:hover span {
			border-bottom-color:currentColor;
		}
	}
	&__allTel--logoKievstar::before {
		background-image: url(../img/iconKievstar.svg);
	}
	&__allTel--logoVodafone::before {
		background-image: url(../img/iconVodafone.svg);
	}
}