.headerButton {
	width: 100%;
	height: 54px;
	background-color: var(--cyan);
	&__btn {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		border: none;
		padding: 10px;
		position: relative;
		outline: none;
		cursor: pointer;
		span {
			display: block;
			background-color: white;
			width: 28px;
			height: 2px;
			will-change: transform;
			transition: transform .3s;
			transform-origin: center center;
			transform: translate(0,0) rotate(0);
		}
		span + span {
			margin-top: 8px;
		}
	}
	@media (width >= 768px) {
		background-color: #FAFAFA;
		span {
			background-color: var(--cyan);
		}
	}
	@media (width >= 1200px) {
		display: none;
	}
}

.menu-is-open .headerButton__btn span:nth-child(1) {
	transform: translateY(10px) rotate(45deg);
}

.menu-is-open .headerButton__btn span:nth-child(2) {
	transform: rotateY(90deg);
}

.menu-is-open .headerButton__btn span:nth-child(3) {
	transform: translateY(-10px) rotate(-45deg);
}