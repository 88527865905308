.dropdownContent {
	display: none;
	opacity: 0;
	transform: translateY(10px);
	transition: opacity .3s, transform .3s;
	&.is-open {
		opacity: 1;
		transform: translateY(0);
	}
	&.transitioning {display: flex;}
}

.dropdownTrigger svg {
	transition: transform .3s;
}
.dropdownTrigger.open svg {
	transform: rotateX(180deg);
}
.dropdownTrigger:not(.open) svg {
	transform: rotateX(0deg);
}
