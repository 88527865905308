.aboutStages {
	width: 100%;
	background-color: #FAFAFA;
	padding-bottom: 45px;
	@media (width >= 768px) {
		padding-top: 45px;
	}
	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		@media (width >= 768px) {
			display: grid;
			grid-template-columns: 170px 1fr;
			align-items: start;
			grid-column-gap: 30px;
		}
	}
	&__heading {
		color: black;
		display: block;
		padding-top: 40px;
		margin-bottom: 30px;
		padding-bottom: 20px;
		position: relative;
		font-size: 24px;
		font-weight: bold;
		text-align: center;
		@media (width >= 768px) {
			padding-top: 0;
			font-size: 32px;
			text-align: left;
			line-height: 1.2;
			grid-row: 1/-1;
			grid-column: 1;
		}
		&::after {
			position: absolute;
			display: block;
			content: "";
			width: 40px;
			height: 2px;
			background-color: var(--orange);
			bottom: 0;
			left: calc(50% - 20px);
			@media (width >= 768px) {
				content: none;
				display: none;
			}
		}
	}
	&__stage {
		display: flex;
		flex-direction: column;
		align-items: center;		
		&:not(:last-child) {margin-bottom: 30px;}
		@media (width >= 768px) {
			grid-row: span 1;
			grid-column: 2;
			align-items: flex-start;
		}
	}
	&__year {
		text-align: center;
		font-size: 26px;
		color: var(--orange);
		margin-bottom: 15px;
		@media (width >= 768px) {text-align: left;}
	}
	&__text {
		text-align: center;
		color: black;
		font-size: 16px;
		@media (width >= 768px) {text-align: left;}
	}
}