.completedProjects {
	padding-top: var(--innerIndent);
	padding-bottom: var(--innerIndent);
	background-color: #FAFAFA;
	&__wrapper {
		margin-bottom: 50px;
		width: 100%;
		text-align: center;
	}
	&__heading {
		padding-top: 0;
		@media (width >= 768px) {
			font-size: 32px;
		}
	}
	&__slider {
		img {
			width: 100%;
			height: auto;
			display: block;
		}
	}
	&__link {
		border: 1px solid var(--orange);
		box-shadow: none;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 0;
	}
}

.lSSlideOuter .lSPager.lSGallery img {
	width: 100%;
}

.lSSlideOuter .lSPager.lSGallery li.active,
.lSSlideOuter .lSPager.lSGallery li:hover {
	border-radius: 0;
	opacity: 0.7;
}


.lSAction>a {
	font-size: 22px;
	line-height: 1;
	font-family: lg;
	background-image: none;
	display: flex;
	width: 50px;
	height: 50px;
	transform: translateY(-50%);
	border-radius: 50%;
	justify-content: center;
	align-items: center;
	background-color: white;
	color: var(--cyan);
	transition: opacity .3s, color .3s, background-color .3s;
	@media (width >= 768px) {
		width: 60px;
		height: 60px;
		
	}
	&:hover {
		background-color: var(--orange);
		color: white;
	}
}

.lSAction>.lSPrev::before {
	content: "\e094";
}

.lSAction>.lSNext::before {
	content: "\e095";
}