.site-header {
	margin-top: 15px;
}

.site-header__bottom {
	@media (width >= 768px) {
		border-top: 1px solid #D9E2E9;
		background-color: #FAFAFA;
	}
	@media (width >= 1200px) {
		transition: box-shadow .3s;
	}
}

.site-header .container {
	@media (width <= 1439px) {
		max-width: 100%;
		padding-left: 0;
		padding-right: 0; 
	}
}

.header-top-wrapper,
.header-bottom-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;
}

.header-top-wrapper {
	padding-left:  20px;
	padding-right: 20px;
	@media (width >= 768px) {
		flex-direction: row;
		justify-content: space-between;
	}
	@media (width >= 1200px) {
		padding-left: 60px;
		padding-right: 60px;
		align-items: center;
		padding-bottom: 15px;
	}
	@media (width >= 1440px) {
		padding-left: 0;
		padding-right: 0;
	}
}

.header-bottom-wrapper {
	@media (width >= 1200px) {
		padding-left: 60px;
		padding-right: 60px;
	}
	@media (width >= 1440px) {
		padding-left: 0;
		padding-right: 0;
	}	
}

.is-fixed .site-header__bottom {
	position: fixed;
	z-index: 4;
	width: 100%;
	left: 0;
	top: 0;
	border-top: none;
	background-color: #FAFAFA;
	box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.2);
	.header-bottom-wrapper {
		justify-content: center;
		height: 70px;
		padding-left: 20px;
		padding-right: 20px;
	}
}