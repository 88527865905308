.headerMenu {
	display: flex;
	width: 100%;
	flex-direction: column;
	list-style: none;
	@media (width >= 1200px) {
		flex-direction: row;
		justify-content: space-between;
		width: auto;
		flex-basis: auto;
		flex-grow: 1;
	}
	&__item {
		display: inline-flex;
		list-style: none;
		border-bottom: 1px solid #AED7EC;
		@media (width >= 1200px) {
			border-bottom: none;
		}
	}
	&__link {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 45px;
		background-color: var(--cyan);
		color: white;
		font-size: 14px;
		line-height: 1;
		font-weight: bold;
		text-transform: uppercase;
		text-decoration: none;
		transition: color .3s, background-color .3s;
		&:hover, &.active {
			background-color: #1776B3;
		}
		@media (width >= 1200px) {
			height: 100%;
			background-color: #FAFAFA;
			color: #333333;
			&:hover, &.active {
				background-color: #FAFAFA;
				color: var(--orange);
			}
		}
	}
}