.substep {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	padding: 30px 10px;
	@media (width >= 768px) {align-items: flex-start;}
	&__icon {
		border-radius: 20px;
		width: 100px;
		height: 100px;
		overflow: hidden;
		transform: rotate(45deg);
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: 10px 10px 15px rgba(0,0,0,0.1);
		@media (width >= 768px) {margin-left: 15px;}
		svg {
			transform: rotate(-45deg);
		}		
	}
	&__heading {
		font-size: 20px;
		font-weight: bold;
		color: black;
		text-align: center;
		margin-top: 30px;
		margin-bottom: 20px;
		@media (width >= 768px) {
			text-align: left;
		}
	}
	&__subheading {
		font-size: 16px;
		color: black;
		text-align: center;
		margin-bottom: 10px;
		@media (width >= 768px) {
			text-align: left;
		}
	}
}