.news-innerContent {
	background-color: white;
	padding-top: var(--innerIndent);
	padding-bottom: var(--innerIndent); 
	&__heading {
		@media (width >= 768px) {
			padding-top: 0;
		}
	}
	&__subheading {
		display: block;
		font-size: 20px;
		color: black;
		text-align: center;
		margin-bottom: 50px;
		max-width: 740px;
		margin-left: auto;
		margin-right: auto;
	}
	&__date {
		display: block;
		text-align: center;
		margin-bottom: 30px;
		margin-top: 0;
		color: #999;
		font-size: 18px;
	}
	&__wrapper {
		width: 100%;
	}
	&__img {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 32px;
		margin-left: auto;
		margin-right: auto;
		picture,img {
			width: 100%;
			height: auto;
			display: block;
			max-width: 290px;
			@media (width >= 480px) {max-width: 450px;}
			@media (width >= 768px) {max-width: 738px;}
			@media (width >= 992px) {max-width: none;}
		}
	}
	img {
		max-width: 100%;
		height: auto;
		margin-left: auto;
		margin-right: auto;
	}
}