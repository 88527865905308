.footerMap {
	display: none;
	position: relative;
	@media (width >= 1440px) {
		display: block;
		max-height: 358px;
	}
	picture, img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: top right;
		display: block;
	}
	&__dot,&__dot::before {
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: var(--orange);		
	}
	&__dot {
		position: absolute;
		display: inline-block;
		pointer-events: auto;
	}
	&__dot--uaDnepr {top: 180px;right: 192px;}
	&__dot--uaZap {top: 200px;right: 192px;}
	&__dot--rbMog {top: 140px;right: 240px;}
	&__dot--ruVol {top: 160px;right: 100px;}
	&__dot--liKaun {top: 110px;right: 290px;}
	&__dot::before {
		top: 0;
		left: 0;
		position: absolute;
		content: "";
		z-index: 0;
		display: block;
		animation: dot-ripple 3s linear infinite;	
	}
	&__dot:nth-of-type(1)::before{animation-delay: .0s;}
	&__dot:nth-of-type(2)::before{animation-delay: .1s;}
	&__dot:nth-of-type(3)::before{animation-delay: .2s;}
	&__dot:nth-of-type(4)::before{animation-delay: .3s;}
	&__dot:nth-of-type(5)::before{animation-delay: .4s;}
	&__link {
		width: 20px;
		height: 20px;
		position: absolute;
		transform: translate(-50%,-50%);
		top: 50%;
		left: 50%;
		z-index: 2;
		display: block;
		cursor: pointer;
	}
}

@keyframes dot-ripple {
	to {
		opacity: 0;
		transform: scale(6)
	}
}
