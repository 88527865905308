.itemText {
	padding-top: var(--innerIndent);
	padding-bottom: var(--innerIndent);
	&__wrapper {
		display: flex;
		flex-direction: column;
		@media (width >= 1200px) {
			flex-direction: row;
		}
	}
	&__half {
		@media (width >= 1200px) {
			flex-basis: 50%;
			&:nth-child(odd) {padding-right: 15px;}
			&:nth-child(even) {padding-left: 15px;}
		}
	}
}