.modal {
	&__content {
		display: flex;
		flex-direction: column;
	}
}

.modalForm {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	&__title {
		font-size: 21px;
		line-height: 1;
		margin-bottom: 20px;
		color: var(--orange);
		font-weight: bold;
		text-transform: uppercase;
	}
	&__addText {
		font-size: 16px;
		font-style: italic;
		color: black;
		margin-top: -5px;
		margin-bottom: 20px;
	}
	&__name,&__email,&__tel,&__message {
		width: 100%;
		height: 45px;
		display: flex;
		align-items: center;
		appearance: none;
		padding: 0 20px;
		border: 1px solid #A8AFC8;
		margin-bottom: 15px;	
		background-color: transparent;
		font-family: var(--fontB);
		font-size: 20px;
		color: black;
		caret-color:  var(--orange);
		&::-webkit-input-placeholder {color: #A8AFC8;}
		&::-moz-placeholder {color: #A8AFC8;}
		&::-ms-input-placeholder {color: #A8AFC8;}
		@media (width >= 480px) {font-size: 22px;}
		&:focus {
			outline: none;
			border-color: var(--orange);
		}
	}
	&__message {
		height: auto;
		min-height: 100px;
		padding-top: 5px;
		resize: vertical;
	}
	&__forFile {
		color: var(--cyan);
		border: 1px solid;
		box-shadow: none;
		margin-left: 0;
		font-weight: normal;
		text-transform: uppercase;
		cursor: pointer;
	}
	&__file {
		display: none;
	}
	&__submit {
		box-shadow: none;
		border: 1px solid var(--orange);
		font-weight: normal;
		text-transform: uppercase;
		margin-left: 0;
		margin-bottom: 0;
		cursor: pointer;
		outline: none;
	}
}