.footerBranches {
	display: none;
	grid-area: branches;
	@media (width >= 992px) {
		display: block;
	}
	&__link {
		font-size: 16px;
		line-height: 2;
		color: #000;
		text-decoration: none;
		transition: color .3s;
		position: relative;
		padding-left: 35px;
		&::before {
			position: absolute;
			width: 22px;
			height: 15px;
			content: "";
			left: 0;
			top: 3px;
		}
		&.flag--ua::before {background-image: url(../img/flag--ua.svg);}
		&.flag--rb::before {background-image: url(../img/flag--rb.svg);}
		&.flag--ru::before {background-image: url(../img/flag--ru.svg);}
		&.flag--lt::before {background-image: url(../img/flag--lt.svg);}
		&:hover, &.active {
			color: var(--orange);
		}
	}
}