.modal {display: none;}
.modal[aria-hidden="true"] {display: none;}
.modal.is-open {display: block;}


@media (width <= 1199px) {
	.modal[aria-hidden="true"] .modal__overlay {display: none;}
	.modal[aria-hidden="false"] .modal__overlay {display: flex;}
}

@media (width >= 1200px) {
	@keyframes mmfadeIn {
		from { opacity: 0; }
		to { opacity: 1; }
	}

	@keyframes mmfadeOut {
		from { opacity: 1; }
		to { opacity: 0; }
	}

	@keyframes mmslideIn {
		from { transform: translateY(15px); }
		to { transform: translateY(0); }
	}

	@keyframes mmslideOut {
		from { transform: translateY(0); }
		to { transform: translateY(15px); }
	}	

	.modal[aria-hidden="false"] .modal__overlay {}
	.modal[aria-hidden="true"] .modal__overlay {}
	.modal[aria-hidden="false"] .modal__container {animation: mmslideIn .2s;}
	.modal[aria-hidden="true"] .modal__container {animation: mmslideOut .2s;}
	.modal .modal__container,.modal .modal__overlay {will-change: opacity, transform;}
}
