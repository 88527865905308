.indexBenefits {
	display: flex;
	flex-direction: column;
	align-items: center;
	@media (width >= 1200px) {
		flex-direction: row;
		align-items: stretch;
	}
	&__items {
		background-color: #FFF;
		display: flex;
		width: 100%;
		flex-direction: column;
		padding: 45px 10px;
		@media (width >= 768px) {
			flex-direction: row;
			flex-wrap: wrap;
			padding: 0;
		}
	}
	&__item {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		@media (width >= 768px) {
			flex-basis: 50%;
			padding: 45px 15px 30px;
		}
		@media (width >= 1200px) {
			flex-basis: 25%;
		}
	}
	@media (width >= 768px) {
		&__item--1,&__item--2 {
			border-top: 1px solid #CDCDCD;
			border-bottom: 1px solid #CDCDCD;
		}
		&__item--1,&__item--2,&__item--3 {
			border-right: 1px solid #CDCDCD;
		}
	}
	@media (width >= 1200px) {
		&__item--3,&__item--4 {
			border-top: 1px solid #CDCDCD;
			border-bottom: 1px solid #CDCDCD;
		}
	}
	&__item:not(:last-child) {
		margin-bottom: 30px;
		@media (width >= 768px) {
			margin-bottom: 0;
		}
	}
	&__icon {
		border-radius: 20px;
		width: 100px;
		height: 100px;
		overflow: hidden;
		transform: rotate(45deg);
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: 10px 10px 15px rgba(0,0,0,0.1);
		svg {
			transform: rotate(-45deg);
		}
	}
	&__counter {
		margin-top: 30px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&__amount {
		font-size: 50px;
		color: #B4BEC7;
		font-weight: bold;
		text-align: center;
	}
	&__text {
		font-size: 16px;
		color: black;
		text-align: center;
	}
}