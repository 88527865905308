.footerEmail {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 20px;
	grid-area: email;
	@media (width >= 768px) {
		align-items: flex-start;
	}
	&__title {
		margin-bottom: 5px;
		@media (width >= 768px) {
			display: none;
		}
	}
	&__link {
		color: var(--orange);
		text-decoration: none;
		border-bottom: 1px solid var(--orange);
		transition: border-bottom-color .3s;
		&:hover {
			border-bottom-color: transparent;
		}
	}
}