.galleryContent {
	background-color: white;
	padding-top: var(--innerIndent);
	padding-bottom: calc(var(--innerIndent) - 15px); 
	&__heading {
		@media (width >= 768px) {
			padding-top: 0;
		}
	}
	&__wrapper {
		width: 100%;
	}
	&__tags {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding-left: 0;
		list-style: none;
	}
	li {
		margin-bottom: 15px;
		width: 100%;
		max-width: 290px;
		margin-left: auto;
		margin-right: auto;
		@media (width >= 480px) {
			width: auto;
			max-width: none;
			margin-left: 0;
			margin-right: 0;
			&:not(:last-child) {
				margin-right: 15px;
			}
		}
	}
	a {
		text-decoration: none;
		color: var(--orange);
		border: 1px solid currentColor;
		background-color: white;
		padding: 12px 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		text-align: center;
		line-height: 1;
		transition: color .3s, background-color .3s;
		border-radius: 23px;
		height: 46px;
		width: 100%;
		@media (width >= 480px) {
			font-size: 16px;
		}
		&:hover {
			color: white;
			background-color: var(--orange);
		}
	}
	.active a {
		color: white;
		background-color: var(--orange);
	}
}