.catalogContent {
	padding-bottom: 45px;
	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
	.indexCatItem {
		border: 1px solid #d9e2e9;
		padding-bottom: 15px;
		margin-bottom: 10px;
		@media (width >= 640px) {
			max-width: 290px;
		}
		@media (width >= 768px) {
			margin: 5px;
		}
		@media (width >= 1200px) {
			max-width: 320px;
		}
	}
}