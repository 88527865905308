.social {
	width: 100%;
	padding-top: 15px;
	padding-bottom: 15px;
	background-color: var(--cyan);
	@media (width >= 768px) {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		@media (width >= 768px) {
			flex-direction: row;
		}
	}
	&__heading {
		font-size: 20px;
		color: #FFF;
		font-weight: bold;
		text-align: center;
		margin-bottom: 10px;
		@media (width >= 768px) {
			margin-right: 15px;
			margin-bottom: 0;
		}
	}
}