.footerDev {
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	transition: opacity .3s;
	grid-area: dev;
	@media (width >= 992px) {
		justify-content: flex-end;
		width: 100%;
	}
	&:hover {
		opacity: 0.7;
	}
	&__text {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		color: #CDCDCD;
		font-size: 14px;
		line-height: 1.2;
		@media (width >= 768px) {
			align-items: flex-start;
		}
	}
	&__logo {
		display: flex;
		justify-content: center;
		padding-top: 2px;
		margin-left: 10px;
	}
}