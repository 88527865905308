.indexNews {
	padding-top: 45px;
	padding-bottom: 45px;
	display: flex;
	flex-direction: column;
	align-items: center;
	&__title {
		z-index: 1;
		width: 100%;
		text-align: center;
		color: #999999;
		font-size: 15px;
		font-weight: bold;
		text-transform: uppercase;
		padding-bottom: 20px;
		margin-bottom: 30px;
		position: relative;
		@media (width >= 768px) {
			font-size: 16px;
		}
		&::after {
			position: absolute;
			display: block;
			content: "";
			background-color: var(--orange);
			width: 40px;
			height: 2px;
			left: calc(50% - 20px);
			bottom: 0;
		}
	}
	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		@media (width >= 768px) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 1fr 1fr;
			grid-template-areas:
			"main item1"
			"main item2";
			grid-column-gap: 15px;
		}
		@media (width >= 1200px) {
			grid-template-columns: 650px 1fr;
		}
		@media (width >= 1440px) {
			grid-template-columns: 750px 1fr;
		}
	}
	&__item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 30px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		max-width: 290px;
		@media (width >= 480px) {
			max-width: 460px;
		}
		@media (width >= 768px) {
			max-width: none;
		}
	}
	&__item--large {
		grid-area: main;
	}
	&__img {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: opacity .3s;
		opacity: 1;
		margin-bottom: 32px;
		picture,img {
			width: 100%;
			height: auto;
			display: block;
		}
		&:hover {opacity: 0.7;}
	}
	&__heading {
		font-size: 18px;
		font-weight: bold;
		line-height: 1.2;
		color: black;
		text-decoration: none;
		margin-bottom: 10px;
	}
	&__subheading {
		font-size: 18px;
		color: black;
		margin-bottom: 20px;
	}
	&__date {
		display: flex;
		align-items: center;
		span {
			font-size: 18px;
			color: #999;
			margin-left: 10px;
		}
	}
	&__link {
		border: 1px solid var(--orange);
		box-shadow: none;
	}
}