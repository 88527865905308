.moreNews {
	background-color: white;
	padding-top: var(--innerIndent);
	padding-bottom: var(--innerIndent); 
	&__heading {
		@media (width >= 768px) {
			font-size: 32px;
			padding-top: 0;
		}
	}
	&__wrapper {
		width: 100%;
		@media (width >= 768px) {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-column-gap: 15px;
		}
	}
	.newsItem:not(:last-child) {
		@media (width <= 767px) {
			margin-bottom: 30px;
		}		
	}
}