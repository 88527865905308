.modal {
	padding-top: 0;
	padding-bottom: 0;
	-webkit-overflow-scrolling: touch;
}

.modal__overlay {
	position: fixed;
	padding: 25px;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	max-height: 100vh;
	overflow-y: auto;
	background: rgba(60, 72, 113, 0.65);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
	-webkit-overflow-scrolling: touch;
	@media (height <= 767px) {
		align-items: flex-start;
	} 
}

.modal__container {
	position: relative;
	background-color: #fff;
	width: 100%;
	max-width: 500px;
	box-sizing: border-box;
	padding: 20px;
	@media (width >= 640px) {padding: 40px;}
}

.modal__close {
	background: transparent;
	border: 0;
	position: absolute;
	top: 0;
	right: 0;
	width: 50px;
	height: 50px;
	border-radius: 25px;
	background-color: var(--cyan);
	transform: translate(50%,-50%);
	transition: background-color .3s;
	padding: 0;
	cursor: pointer;
	outline: none;
	&:hover {
		background-color: var(--orange);
	}
}

.modal__close-cross {
	position: absolute;
	display: block;
	width: 20px;
	height: 20px;
	top: calc(50% - 10px);
	left: calc(50% - 10px);
	transform: rotate(45deg);
	transform-origin: center center;
	opacity: 0.7;
	transition: transform .5s, opacity .5s;
	pointer-events: none;
}

.modal__close-cross::before,
.modal__close-cross::after {
	display: block;
	position: absolute;
	content: "";
	background-color: white;
}

.modal__close-cross::before {
	width: 20px;
	height: 2px;
	top: 9px;
	left: 0;
}

.modal__close-cross::after {
	width: 2px;
	height: 20px;
	top: 0;
	left: 9px;
}

.modal__close:hover .modal__close-cross {
	opacity: 1;
	transform: rotate(225deg);
}

.modal__close:hover .modal__close-cross::before,
.modal__close:hover .modal__close-cross::after {
	background-color: white;
}