.indexAbout {
	width: 100%;
	padding-bottom: 30px;
	@media (width >= 768px) {
		padding-bottom: 50px;
	}
	@media (width >= 992px) {
		padding-top: 50px;
		background-color: #FAFAFA;
	}
	&__wrapper{
		display: flex;
		flex-direction: column;
		align-items: center;
		@media (width >= 768px) {
			display: grid;
			width: 100%;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: auto 1fr 1fr;
			grid-template-areas:
			"main main"
			"item1 item2"
			"item3 item4";
			grid-column-gap: 50px;
			align-items: flex-start;
		}
		@media (width >= 992px) {
			grid-template-columns: 310px 1fr 1fr;
			grid-template-rows: 1fr 1fr;
			grid-template-areas:
			"main item1 item2"
			"main item3 item4";
			grid-column-gap: 90px;
		}
	}
	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 30px 15px 0;
		@media (width >= 768px) {
			padding-left: 0;
			padding-right: 0;
			align-items: flex-start;
		}
		@media (width >= 992px) {
			padding-top: 0;
		}
	}
	&__item--main {
		margin-left: -15px;
		margin-right: -15px;
		width: calc(100% + 30px);
		background-color: #FAFAFA;
		padding-bottom: 30px;
		grid-area: main;
		@media (width >= 768px) {
			align-items: center;
			padding-top: 50px;
			padding-bottom: 50px;
			.indexAbout__itemSubheading {
				text-align: center;
				max-width: 530px;
				margin-left: auto;
				margin-right: auto;
			}
		}
		@media (width >= 992px) {
			padding-top: 0;
			align-items: flex-start;
			margin-left: 0;
			margin-right: 0;
			width: 100%;
			background-color: transparent;
			.indexAbout__itemSubheading {
				text-align: left;
			}
		}
	}
	&__item--1 {grid-area: item1;}
	&__item--2 {grid-area: item2;}
	&__item--3 {grid-area: item3;}
	&__item--4 {grid-area: item4;}
	&__icon {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 20px;
		min-height: 50px;
		path {transition: fill .3s;}
		@media (width >= 768px) {justify-content: flex-start;}
	}
	&__itemHeading {
		font-size: 20px;
		color: black;
		font-weight: bold;
		text-align: center;
		padding-bottom: 15px;
		margin-bottom: 30px;
		position: relative;
		&::after {
			position: absolute;
			display: block;
			content: "";
			background-color: var(--orange);
			transition: background-color .3s;
			width: 40px;
			height: 2px;
			left: calc(50% - 20px);
			bottom: 0;
			@media (width >= 992px) {left: 0;}
		}
		@media (width >= 992px) {text-align: left;}
	}
	&__item--main:hover .indexAbout__itemHeading::after {
		background-color: var(--cyan);
	}
	&__itemSubheading {
		font-size: 16px;
		color: black;
		text-align: center;
		margin-bottom: 10px;
		@media (width >= 768px) {text-align: left;}
	}
	&__link {
		font-size: 16px;
		color: var(--orange);
		text-decoration: none;
		border-bottom: 1px solid currentColor;
		transition: color .3s;
		&:hover {
			color: var(--cyan);
		}
	}
	&__item:hover path {
		fill: var(--cyan);
	}
}