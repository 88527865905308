.footerSchedule {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 30px;
	grid-area: schedule;
	@media (width >= 768px) {
		margin-bottom: 0;
		align-items: flex-start;
	}
	&__title {
		margin-bottom: 5px;
		@media (width >= 768px) {
			display: none;
		}
	}
	&__text {
	}
}