.itemBanner {
	width: 100%;
	&__item {
		width: 100%;
		position: relative;
	}
	&__img {
		position: relative;
		&::after {
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			content: "";
			background-color: rgba(30, 149, 225, .5);
		}		
	}
	picture,img {
		display: block;
		width: 100%;
		object-fit: cover;
		object-position: center;
		height: 300px;
		@media (width >= 480px) {height: 420px;}
	}
	&__text {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 15px;
	}
	&__heading {
		color: #FFF;
		text-align: center;
		font-weight: 700;
		line-height: 1;
		font-size: 24px;
		margin-bottom: 30px;
		@media (width >= 768px) {font-size: 32px;}
		@media (width >= 1200px) {font-size: 38px;}
	}
	&__links {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	&__link {
		height: 50px;
		font-size: 14px;
		line-height: 1;
		font-weight: 700;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 25px;
		text-decoration: none;
		--btnPadding: 16px;
		margin: 0 var(--btnPadding) var(--btnPadding) var(--btnPadding);
		padding: var(--btnPadding) calc(var(--btnPadding) * 2);
		box-shadow: 0 16px 11px rgba(0, 0, 0, .15);
		width: 100%;
		max-width: 250px;
		@media (width >= 480px) {
			width: auto;
		}
	}
}