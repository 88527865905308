.newsContent {
	background-color: white;
	padding-top: var(--innerIndent);
	padding-bottom: var(--innerIndent); 
	&__heading {
		@media (width >= 768px) {
			padding-top: 0;
		}
	}
	&__wrapper {
		width: 100%;
		@media (width >= 768px) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 18px;
			grid-row-gap: 40px;
		}
		@media (width >= 992px) {
			grid-column-gap: 20px;
		}
	}
}