.itemSizes {
	background-color: #FAFAFA;
	padding-top: var(--innerIndent);
	padding-bottom: var(--innerIndent); 
	&__heading {
		@media (width >= 768px) {
			font-size: 32px;
		}
	}
	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
}

.sizesCard {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 290px;
	margin-right: auto;
	margin-left: auto;
	margin-top: 15px;
	background-color: #FFF;
	box-shadow: 0 3px 6px rgba(0,0,0,.1);
	@media (width >= 768px) {
		margin: 5px;
		max-width: 320px;
	}
	@media (width >= 1200px) {
		max-width: calc(25% - 20px);
		
	}
	&__name {
		width: 100%;
		background-color: #D8E1E9;
		color: #000;
		font-size: 20px;
		font-weight: bold;
		padding: 20px;
		text-align: center;
		text-decoration: none;
		transition: opacity .3s;
		&:hover {
			opacity: 0.7;
		}
	}
	&__list {
		width: 100%;
		display: flex;
		flex-direction: column;
		padding: 25px 15px;
		li {
			display: flex;
			justify-content: space-between;
			margin-top: 10px;
			margin-bottom: 10px;
		}
		span {
			font-size: 16px;
			line-height: 1;
		}
		i {
			border-bottom: 1px dotted black;
			flex-grow: 1;
			margin-left: 5px;
			margin-right: 5px;
		}
	}
	&__request {
		width: 100%;
		height: 60px;
		background-color: var(--orange);
		display: flex;
		justify-content: center;
		align-items: center;
		color: white;
		transition: background-color .3s;
		text-decoration: none;
		&:hover {
			background-color: var(--cyan);
		}
		span {
			margin-left: 10px;
			font-size: 13px;
			font-weight: bold;
			text-transform: uppercase;
		}
		path {
			fill: currentColor;
		}
	}
}