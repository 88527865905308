.site-footer {
	display: flex;
}
.site-footer-wrapper {
	width: 100%;
	padding: 30px 10px 20px;
	@media (width >= 768px) {
		padding: 35px 20px 20px;
		display: grid;
		grid-template-rows: repeat(5, min-content);
		grid-template-areas:
		"phones menu subscribe"
		"email menu social"
		"address menu ."
		"schedule menu ."
		"copyright menu dev";
		grid-column-gap: 40px;
		justify-items: start;
	}
	@media (width >= 992px) {
		padding: 40px 20px 30px;
		grid-template-areas:
		"phones branches menu subscribe"
		"email branches menu social"
		"address branches menu ."
		"schedule branches menu ."
		"copyright branches menu dev";
	}
	@media (width >= 1200px) {
		padding: 40px 30px 20px;
	}
	@media (width >= 1440px) {
		padding: 40px 30px 45px;
		max-width: 70%;
		margin-left: auto;
	}
	@media (width >= 1800px) {
		padding: 40px 60px 50px;
		max-width: 1380px;		 
	}
}


.footer-title {
	font-size: 16px;
	line-height: 1.5;
	font-weight: bold;
	color: #000;
}

.footer-title-decor {
	display: block;
	font-size: 14px;
	line-height: 1;
	font-weight: bold;
	text-transform: uppercase;
	padding-bottom: 20px;
	margin-bottom: 30px;
	position: relative;
	&::after {
		position: absolute;
		display: block;
		content: "";
		bottom: 0;
		left: 0;
		width: 25px;
		height: 2px;
		background-color: var(--orange);
	}
}

.footer-text {
	font-size: 16px;
	line-height: 1.5;
	color: #000000;	
	@media (width >= 768px) {
		font-size: 18px;
	}
}