.headerLogo {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-decoration: none;
	transition: opacity .3s;
	margin-bottom: 15px;
	&:hover {
		opacity: 0.7;
	}
	&__title {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-top: 5px;
		span {
			font-size: 15px;
			color: #1D1D1B;
			margin-left: 3px;
			margin-right: 3px;
		}
	}
	@media (width >= 768px) {
		width: auto;
		flex-direction: row;
		&__title {
			flex-direction: column;
			margin-left: 10px;
		}
	}
	@media (width >= 1200px) {
		margin-bottom: 0;
		img {
			width: 160px;
			height: auto;
		}
	}
}