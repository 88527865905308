.paymentStep {
	width: 100%;
	padding: 30px 15px;
	&:nth-child(odd) {background-color: #FAFAFA;}
	@media (width >= 768px) {
		flex-direction: row;
		align-items: flex-start;
	}
	&__wrapper {
		width: 100%;
		max-width: var(--container);
		margin-left: auto;
		margin-right: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		@media (width >= 768px) {
			flex-direction: row;
			align-items: flex-start;
		}
	}
	&__title {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: baseline;
		margin-bottom: 15px;
		@media (width >= 768px) {
			flex-basis: 300px;
			justify-content: flex-start;
			padding-right: 15px;
			transform: translateY(-25px);
		}
		span {
			font-size: 40px;
			font-weight: bold;
			color: var(--orange);
		}
		b {
			font-size: 20px;
			font-weight: bold;
			color: black;
			margin-left: 15px;
		}
	}
	&__content {
		width: 100%;
		@media (width >= 768px) {
			flex-basis: calc(100% - 300px);
			width: auto;
		}
		ul li {
			position: relative;
			&::before {
				content: "• ";
				color: var(--orange);
			}
		}
	}
	h3 {
		margin-top: 0;
	}
	p, li {
		font-size: 16px;
		color: black;
		margin-top: 0;
		margin-bottom: 20px;
	}
	&__link {
		margin-left: auto;
		margin-right: auto;
		@media (width >= 768px) {
			margin-left: 0;
		}
	}
}

@media (width >= 1200px) {
	.paymentStep--2 .paymentStep__content {
		display: flex;
		flex-wrap: wrap;
		.substep {
			flex-basis: 50%;
		}
	}	
}
