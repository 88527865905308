.footerPhones {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 10px;
	grid-area: phones;
	@media (width >= 768px) {
		margin-bottom: 0;
	}
	&__title {
		margin-bottom: 5px;
		@media (width >= 768px) {
			display: none;
		}
	}
	&__item {
		@media (width >= 768px) {
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}
	}
	&__link {
		text-decoration: none;
		border-bottom: 1px solid transparent;
		transition: border-bottom-color .3s;
		b {
			font-weight: normal;
			@media (width >= 768px) {
				font-weight: bold;
			}
		}
		&:hover {
			border-bottom-color: currentColor;
		}
	}
}