.indexTestimonials {
	width: 100%;
	height: auto;
	padding-top: 40px;
	padding-bottom: 40px;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media (width >= 768px) {
		padding-top: 80px;
		padding-bottom: 60px;
	}
	&__bg {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 0;
		picture,img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
	&__title {
		z-index: 1;
		width: 100%;
		text-align: center;
		color: #FFF;
		font-size: 15px;
		font-weight: bold;
		text-transform: uppercase;
		padding-bottom: 20px;
		margin-bottom: 30px;
		position: relative;
		@media (width >= 768px) {
			font-size: 16px;
		}
		&::after {
			position: absolute;
			display: block;
			content: "";
			background-color: var(--orange);
			width: 40px;
			height: 2px;
			left: calc(50% - 20px);
			bottom: 0;
		}
	}
	&__slider {
		z-index: 1;
		width: 100%;
		padding-left: 10px;
		padding-right: 10px;
		@media (width >= 768px) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	&__controls {
		display: none;
		@media (width >= 1200px) {
			position: absolute;
			z-index: 1;
			width: 1000px;
			height: 100%;
			top: 50%;
			left: calc(50% - 500px);
			display: flex;
			justify-content: space-between;
			pointer-events: none;
			.left,.right {
				width: 100px;
				height: 50px;
				pointer-events: all;
				z-index: 1;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				transition: transform .3s;
			}
			path {
				fill: #cdcdcd;
				transition: fill .3s;
			}
			.left:hover {transform: translateX(-10px);}
			.right:hover {transform: translateX(10px);}
			div:hover path {fill: white}
		}
	}
	&__controls.active {
		@media (width >= 1200px) {
			border-left-color: transparent;
		}		
	}
	.owl-carousel.owl-theme .owl-dots {
		margin-top: 40px;
		span {background-color: #E5E5E5}
		.active span {background-color: var(--orange);}
	}
}