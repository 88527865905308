.footerAddress {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 10px;
	grid-area: address;
	&__title {
		margin-bottom: 5px;
		@media (width >= 768px) {
			display: none;
		}
	}
	&__text {
	}
}