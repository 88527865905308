.pager {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	margin-bottom: 50px;
	.pagination {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	li {
		width: 50px;
		height: 50px;
		margin-left: 5px;
		margin-right: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		color: #B4BEC7;
		transition: color .3s;
		&:not(.disabled):hover,&.active {
			color: var(--cyan);
		}
		&::after {
			position: absolute;
			display: block;
			width: 34px;
			height: 34px;
			border-radius: 3px;
			transform: rotate(45deg);
			content: "";
			border: 1px solid currentColor;
			pointer-events: none;
			top: 9px;
			left: 7px;
		}
		@media (width <= 479px) {			
			width: 30px;
			height: 30px;
			&::after {
				width: 28px;
				height: 28px;
			}			
		}
	}
	a {
		text-decoration: none;
		font-size: 20px;
		line-height: 1;
		color: currentColor;
		padding: 20px;
		padding-right: 20px;
	}
	span {
		padding: 20px;
		color: currentColor;
	}
	path {
		fill: currentColor;
	}
}