.indexCatItem {
	width: 100%;
	max-width: 290px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 50px 30px 0 30px;
	color: black;
	text-decoration: none;
	min-height: 586px;
	@media (width >= 640px) {
		padding-bottom: 30px;
		background-image: linear-gradient(to bottom, white 35%, #FFC366 70%, #FF8133 99%);
		background-size: auto 280%;
		background-position: top center;
		transition: background-position .3s ease-out, color .3s .15s;
		max-width: 320px;
		&:hover {
			color: white;
			background-position: bottom center;
		}
	}
	@media (width >= 1200px) {
		align-items: flex-start;
		max-width: 380px;		
		border-right: 1px solid #D9E2E9;
	}
	@media (width >= 1440px) {
		max-width: 330px;
	}
	&__img {
		border-radius: 20px;
		width: 130px;
		height: 130px;
		overflow: hidden;
		transform: rotate(45deg);
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: 10px 10px 15px rgba(0,0,0,0.1);
		@media (width >= 1200px) {margin-left: 20px;}
		img {
			display: block;
			width: 170px;
			height: 170px;
			object-fit: cover;
			object-position: center;
			transform: rotate(-45deg);
		}
	}
	&__title {
		display: block;
		margin-top: 45px;
		margin-bottom: 10px;
		font-size: 16px;
		min-height: 100px;
		font-weight: bold;
		color: currentColor;
		text-align: center;
		@media (width >= 992px) {min-height: 75px}
		@media (width >= 1200px) {text-align: left;}
	}
	&__description {
		display: block;
		margin-top: 0;
		font-size: 16px;
		color: currentColor;
		text-align: center;
		@media (width >= 1200px) {text-align: left;}
	}
	&__decor {
		display: none;
		@media (width >= 1200px) {
			display: block;
			margin-top: auto;
			padding-top: 30px;
			path {fill: currentColor;}
		}
	}
}