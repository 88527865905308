.itemInstall {
	background-color: white;
	padding-top: var(--innerIndent);
	padding-bottom: var(--innerIndent);
	&__heading {
		padding-top: 0;
		@media (width >= 768px) {
			font-size: 32px;
		}
	}
	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
}