.headerLangs {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
	@media (width >= 1200px) {
		margin-bottom: 0;
	}
	&__lang {
		margin-left: 6px;
		margin-right: 6px;
	}
	&__link {
		width: 30px;
		height: 30px;
		display: block;
		border-radius: 15px;
		border: 1px solid #D9E2E9;
		color: #333333;
		font-size: 12px;
		line-height: 30px;
		text-transform: uppercase;
		text-decoration: none;
		text-align: center;
		transition: color .3s, border-color .3s;
		&:hover,&.active {
			color: var(--cyan);
			border-color: var(--cyan);
		}
	}
}