.headerCert {
	display: none;
	text-decoration: none;
	@media (width >= 1440px) {
		display: flex;
		align-items: center;
		transition: opacity .3s;
		&:hover {
			opacity: 0.7;
		}
		&__title {
			padding-left: 15px;
			display: flex;
			flex-direction: column;
			span {
				color: #333;
				font-size: 14px;
				line-height: 1.5;
			}
		}
	}
}