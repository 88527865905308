.headerMenuWrapper {
	position: absolute;
	top: 54px;
	left: 0;
	display: none;
	height: 50px;
	width: 100%;
	background-color: var(--cyan);
	z-index: 2;
	@media (width >= 1200px) {
		position: static;
		display: flex;
		background-color: #FAFAFA;
	}
}

.menu-is-open .headerMenuWrapper {
	display: block;
}