.testimonialsContent {
	background-color: white;
	padding-top: var(--innerIndent);
	padding-bottom: var(--innerIndent);
	min-height: 50vh;
	&__heading {
		@media (width >= 768px) {
			padding-top: 0;
		}
	}
	&__subheading {
		display: block;
		font-size: 20px;
		color: black;
		text-align: center;
		margin-bottom: 50px;
	}
	&__wrapper {
		width: 100%;
	}
}